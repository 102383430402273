<template>
  <Layout>
    <div class="mb-3">
      <div class="ride-steps mb-4">
        <a
          href="/ride/step-1"
          @click.prevent="toFirstStep"
          class="ride-step active"
        >
          <div class="ride-step-index">
            <span class="material-symbols-rounded"> done </span>
          </div>
          <div class="ride-step-title">Choose Route</div>
        </a>
        <div class="ride-step active">
          <div class="ride-step-index">2</div>
          <div class="ride-step-title">Select Company</div>
        </div>
        <div class="ride-step">
          <div class="ride-step-index">3</div>
          <div class="ride-step-title">Payment</div>
        </div>
      </div>

      <b-alert
        :variant="msg.type"
        dismissible
        class="mt-3 col-lg-6 mx-lg-auto"
        v-model="msg.has"
        :show="msg.text"
        >{{ msg.text }}</b-alert
      >

      <div class="row">
        <div class="col-xl-4 mb-3 mb-md-0">
          <div class="row">
            <div class="col-md-6 col-xl-12">
              <h3 class="header-title">Route Information</h3>

              <div class="card">
                <div class="card-body order-route-text" v-if="info">
                  <p v-if="info.pfrom_addr">
                    <b>Starting Point</b>
                    {{ info.pfrom_addr }}
                  </p>
                  <p v-if="info.pto_addr">
                    <b>End Point</b>
                    {{ info.pto_addr }}
                  </p>
                  <p v-if="info.distance">
                    <b>Distance</b>
                    {{ info.distance }} miles
                  </p>
                  <p v-if="info.cartype">
                    <b>Car type</b>
                    {{ carTypesLabel(info.cartype) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-12">
              <h3 class="header-title">Price Filter</h3>

              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group my-0">
                        <div class="input-group input-group-merge">
                          <input
                            v-model.number="filter.from"
                            v-mask="currencyMask"
                            placeholder="$"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.filter.from.$error,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group my-0">
                        <div class="input-group input-group-merge">
                          <input
                            v-model.number="filter.to"
                            v-mask="currencyMask"
                            placeholder="$"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': $v.filter.to.$error,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="companies.length"
                class="my-3 text-center text-md-left"
              >
                <!-- <div class="my-3 text-center text-md-left"> -->
                <button
                  class="btn btn-outline-primary"
                  @click.prevent="orderCompany(companies.map(({ id }) => id))"
                  :disabled="wait"
                  v-if="companies.length >= 2"
                >
                  Send order to all companies
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 mb-3 mb-md-0">
          <div class="row">
            <div class="col-auto">
              <h3 class="header-title">Companies</h3>
            </div>
            <div v-if="hasFilter" class="col-auto ml-auto">
              <div class="header-title header-title-reset">
                Filter price: ${{ Number(filter.from) }} - ${{
                  Number(filter.to)
                }}
                <a
                  href="#"
                  @click.prevent="(filter.from = ''), (filter.to = '')"
                  ><span class="material-symbols-rounded v-middle fz-18">
                    cancel
                  </span></a
                >
              </div>
            </div>
          </div>

          <template v-if="companies.length">
            <div
              v-for="company in companies"
              :key="company.id"
              class="card company-card"
            >
              <div class="card-body">
                <div class="company-card-head">
                  <div class="company-card-head-title">
                    {{ company.company_name }}
                  </div>
                  <!-- overtimes via user time ??? -->
                  <div class="company-card-head-price">
                    $ {{ company.order.price }}
                  </div>
                  <div class="company-card-head-btn ml-sm-2">
                    <button
                      @click.prevent="orderCompany([company.id])"
                      :disabled="wait"
                      class="btn btn-primary"
                    >
                      Order
                    </button>
                  </div>
                </div>
                <div class="company-card-info">
                  <!-- <pre>company.order: {{ company.order }}</pre> -->

                  <!-- baseprice -->
                  <!-- <pre>company.order: {{company.order}}</pre> -->
                  <div
                    v-if="company.order.pricemk.baseprice"
                    class="company-card-descr"
                  >
                    <div class="company-card-descr-title">Base price</div>
                    <div class="company-card-descr-div">-</div>
                    <div class="company-card-descr-price">
                      $ {{ company.order.pricemk.baseprice }}
                    </div>
                  </div>

                  <!-- distance price -->
                  <div
                    v-if="company.order.pricemk.costm"
                    class="company-card-descr"
                  >
                    <div class="company-card-descr-title">
                      Ride {{ company.order.distance }} mi *
                      {{ company.order.pricemk.pricem }} $/mi
                    </div>
                    <div class="company-card-descr-div">-</div>
                    <div class="company-card-descr-price">
                      $ {{ company.order.pricemk.costm }}
                    </div>
                  </div>

                  <!-- return price -->
                  <div
                    v-if="company.order.pricemk.returncostm"
                    class="company-card-descr"
                  >
                    <div class="company-card-descr-title">
                      Return Ride {{ company.order.distance }} mi *
                      {{ company.order.pricemk.pricem }} $/mi
                    </div>
                    <div class="company-card-descr-div">-</div>
                    <div class="company-card-descr-price">
                      $ {{ company.order.pricemk.returncostm }}
                    </div>
                  </div>

                  <!-- services  -->
                  <div
                    v-for="service in companyServices(
                      company.order.pricemk,
                      company.services
                    )"
                    :key="service.id"
                    class="company-card-descr"
                  >
                    <div class="company-card-descr-title">
                      {{ service.t }}
                    </div>
                    <div class="company-card-descr-div">-</div>
                    <div class="company-card-descr-price">
                      $ {{ service.price }}
                    </div>
                  </div>

                  <!-- overtime -->
                  <div
                    v-if="
                      company.order.pricemk.overtime && company.order.overtime
                    "
                    class="company-card-descr"
                  >
                    <div class="company-card-descr-title">
                      Overtime
                      {{ getTimeFormatted(company.order.overtime.from) }} -
                      {{ getTimeFormatted(company.order.overtime.to) }}
                    </div>
                    <div class="company-card-descr-div">-</div>
                    <div class="company-card-descr-price">
                      $ {{ company.order.overtime.price }}
                    </div>
                  </div>

                  <!-- commission -->
                  <div
                    v-if="company.order.pricemk.commission"
                    class="company-card-descr"
                  >
                    <div class="company-card-descr-title">Service Fee</div>
                    <div class="company-card-descr-div">-</div>
                    <div class="company-card-descr-price">
                      $ {{ company.order.pricemk.commission }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="header-title my-5 text-center">Not found companies...</p>
          </template>
        </div>

        <b-modal v-model="showModalNoAuth" hide-header hide-footer centered>
          <div class="common-modal-headline">
            <h4 class="common-modal-title">
              In order to book a ride, please, log in or sign up.
            </h4>
            <span
              class="material-symbols-rounded common-modal-close"
              @click="showModalNoAuth = false"
            >
              close
            </span>
          </div>
          <hr class="my-3" />
          <div class="row mt-3">
            <div class="col-6">
              <button
                @click.prevent="leavePageTo('/login?tab=user')"
                class="btn btn-primary d-block w-100"
              >
                Log in
              </button>
            </div>
            <div class="col-6">
              <button
                @click.prevent="leavePageTo('/signup')"
                class="btn btn-outline-primary d-block w-100"
              >
                Sign up
              </button>
            </div>
          </div>
        </b-modal>

        <!-- <div class="col-12"> -->
        <!-- <pre>companies: {{ companies }}</pre> -->
        <!-- <pre>vendorlist: {{ vendorlist }}</pre> -->
        <!-- <pre>info: {{ info }}</pre> -->
        <!-- <pre>routeData: {{ routeData }}</pre> -->
        <!-- <pre>client: {{ client }}</pre> -->
        <!-- <pre>orderData.vendorlist: {{ orderData.vendorlist }}</pre> -->
        <!-- </div> -->
      </div>
    </div>
  </Layout>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { mapGetters } from "vuex";
import Layout from "@/views/layouts/main";
import {
  genderTypes,
  escortTypes,
  carTypes,
  mapFields,
  timeline,
} from "@/components/data";
import axios from "axios";
import urls from "@/urls";
import {
  between,
  minLength,
  maxLength,
  sameAs,
  requiredIf,
} from "vuelidate/lib/validators";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | Book a ride, step 2: Select Company",
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log("step-2, beforeRouteEnter... ");
  //   // console.log("store.getters.step: ", store.getters.step);
  //   // no data || step !== 2 - go to step-1
  //   if (store.getters.authorized && store.getters.user.role === "c") {
  //     if (Number(store.getters.step) === 1) {
  //       router.push("/ride/step-1").catch(() => { });
  //     } else if (store.getters.client?.currentorders?.items[0]?.status === 1) {
  //       router.push("/ride/step-3").catch(() => { });
  //     } else {
  //       next();
  //     }
  //   } else {
  //     next();
  //   }
  // },
  components: {
    Layout,
  },
  data() {
    return {
      wait: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      carTypes: [...carTypes],
      timeline: timeline,
      filter: {
        from: "",
        to: "",
      },
      currencyMask: createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: false,
        allowNegative: false,
        thousandsSeparatorSymbol: " ",
      }),
      hasFilter: false,
      showModalNoAuth: false,
      vendorlist: [],
    };
  },
  validations() {
    return {
      filter: this.filterRules,
    };
  },
  created() {
    this.searchVendor();

    // has any order ? else search vendor....
    if (this.client.currentorders?.total) {
      // has new order ?, show wait accept modal...
      // if (this.client.currentorders.items[0]?.status === 1) {
      //   store.commit("modalWaitOrderAccept", true);
      // }
    }
  },
  computed: {
    ...mapGetters(["authorized", "user", "opt", "routeData", "step", "client"]),
    filterRules() {
      let rules = {};

      rules.from = {
        between: between(0, 99999),
      };

      rules.to = {
        between: between(0, 99999),
      };

      return rules;
    },
    info() {
      // console.log("step-2, info");
      if (this.routeData && this.routeData.formData && this.routeData.addData) {
        return { ...this.routeData.formData, ...this.routeData.addData };
      }

      return null;
    },
    companies() {
      let filtered = [];

      // filter by from to
      if (
        !isNaN(this.filter.from) &&
        this.filter.to &&
        !isNaN(this.filter.to) &&
        this.filter.to > this.filter.from
      ) {
        this.hasFilter = true;

        filtered =
          this.vendorlist.filter(
            (v) =>
              Number(v.order.price) >= Number(this.filter.from) &&
              Number(v.order.price) <= Number(this.filter.to)
          ) || [];
      } else {
        this.hasFilter = false;
        filtered = this.vendorlist || [];
      }

      // sort to major
      return filtered.sort((a, b) => a.order.price - b.order.price);
    },
  },
  methods: {
    async searchVendor() {
      // req for searching if ok show, else redirect to step-1
      // console.log("searchVendor, routeData: ", this.routeData);

      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      try {
        const response = await axios({
          method: "post",
          url: urls.URL_ORDER_VENDOR_SEARCH,
          data: { ...this.routeData.formData },
        });
        // console.log("searchVendor, response: ", response);
        this.vendorlist = response.data.vendorlist?.items || [];
      } catch (error) {
        console.log("searchVendor, Error: ", error);

        this.msg = {
          has: true,
          type: "danger",
          text: "Search vendors error...",
        };

        if (error.response?.status) {
          if (error.response.statusText) {
            this.msg.text = `Error: ${error.response.status} ${error.response.statusText}`;
          } else if (error.response.data?.detail) {
            this.msg.text = `Error: ${error.response.status} ${error.response.data.detail}`;
          } else if (error.response.data?.error) {
            this.msg.text = `Error: ${error.response.status} ${error.response.data.error}`;
          }
        }

        router.push({ name: "ride-step-1", params: { msg } }).catch(() => {});
        // store.commit("setStep", 1);
      }
    },
    carTypesLabel(id) {
      return this.carTypes.find((t) => t.id === id).label;
    },
    async orderCompany(ids) {
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      if (!this.authorized) {
        this.showModalNoAuth = true;
        return;
      }

      // if (id) {
      //   console.log("orderCompany(), id: ", id);
      // } else {
      //   console.log("orderCompany() ALL...");
      // }

      this.wait = true;

      try {
        const response = await axios({
          method: "post",
          url: urls.URL_ORDER_CREATE,
          data: {
            vendor_ids: ids,
            ...this.routeData.addData,
            ...this.routeData.formData,
          },
        });
        console.log("orderCompany, response: ", response);

        // show modal
        // console.log("step2, show modalWaitOrderAccept ...");
        // store.commit("modalWaitOrderAccept", true);

        // sync data
        // await store.dispatch("fetchUserDataCustomer");

        router.push("/client/orders").catch(() => {});
      } catch (error) {
        console.log("orderCompany, Error: ", error);

        this.msg = {
          has: true,
          type: "danger",
          text: "Create order error...",
        };

        if (error.response?.status) {
          if (error.response.statusText) {
            this.msg.text = `Error: ${error.response.status} ${error.response.statusText}`;
          } else if (error.response.data?.detail) {
            this.msg.text = `Error: ${error.response.status} ${error.response.data.detail}`;
          } else if (error.response.data?.error) {
            this.msg.text = `Error: ${error.response.status} ${error.response.data.error}`;
          }
        }
      } finally {
        this.$scrollToTop();
        this.wait = false;
      }
    },
    leavePageTo(url) {
      router.push(url).catch(() => {});
    },
    toFirstStep() {
      // store.commit("setStep", 1);
      router.push("/ride/step-1").catch(() => {});
    },
    companyServices(pricemk, services) {
      // console.log("hasService, pricemk: ", pricemk);
      // console.log("hasService, services: ", services);
      return services.filter((service) =>
        Object.keys(pricemk).includes(service.c)
      );
    },
    getTimeFormatted(f) {
      return this.timeline.find((t) => t.f === String(f))?.name || "";
    },
  },
  watch: {
    filter: {
      handler: function () {
        // console.log("watch filter...");
        this.$v.filter.$touch();
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.header-title {
  line-height: 1.1;
}

.order-route-text p {
  font-size: 16px;
  font-weight: 400;
  color: #505655;
  margin: 0.75rem 0 0 0;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }

  b {
    display: block;
    font-size: 14px;
    font-weight: 600;
  }
}

.company-card-head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;

  &-title {
    flex: 1 1 auto;
    align-self: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 28px;
    color: #293331;
  }

  &-price {
    flex: 0 1 148px;
    align-self: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: #199f97;
    white-space: nowrap;
    margin-left: 20px;
  }

  &-btn {
    flex: 0 1 auto;
    align-self: auto;
    text-align: right;

    .btn {
      min-width: 106px;
    }
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;

    &-title {
      flex: 1 1 auto;
      font-size: 24px;
    }

    &-price {
      flex: 0 1 auto;
      font-size: 26px;
      text-align: right;
    }

    &-btn {
      flex: 1 1 100%;
      text-align: center;
      margin: 10px 0 20px 0;
    }
  }
}

.company-card-descr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #505655;
  max-width: 330px;

  &-title {
    flex: 0 1 auto;
    align-self: auto;
    min-width: 208px;
  }

  &-div {
    flex: 0 1 40px;
    align-self: auto;
    text-align: center;
    min-width: 40px;
    margin-left: auto;
  }

  &-price {
    flex: 0 1 auto;
    align-self: auto;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.header-title-reset {
  color: #acb4b8;

  a {
    color: #acb4b8 !important;
  }

  .material-symbols-rounded {
    position: relative;
    top: -3px;
    margin-bottom: -3px;
  }
}
</style>
